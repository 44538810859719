import React, { Suspense, lazy } from "react";
import Header from "./landing-page/ui/header";

// Lazy load components
const Hero = lazy(() => import("./landing-page/hero"));
const Features01 = lazy(() => import("./landing-page/features-01"));
const Features02 = lazy(() => import("./landing-page/features-02"));
const Features03 = lazy(() => import("./landing-page/features-03"));
const Community = lazy(() => import("./landing-page/community"));
const Blocks = lazy(() => import("./landing-page/blocks"));
const Testimonials = lazy(() => import("./landing-page/testimonials"));
const PricingTabs = lazy(() => import("./landing-page/pricing-tabs"));
const Cta = lazy(() => import("./landing-page/cta"));

export default function App() {
  return (
    <>
      <Header />
      <div className="min-h-screen bg-gray-100">
        <Suspense fallback={<div>Loading...</div>}>
          <Hero />
          <Features01 />
          <Features02 />
          <Features03 />
          <Community />
          <Blocks />
          <Testimonials />
          <PricingTabs />
          <Cta />
        </Suspense>
      </div>
    </>
  );
}
